import React from 'react'
import { classNames } from '../../../utils/misc'
import { ArrowRightIcon } from '@heroicons/react/outline'

const ButtonPrimary = ({
  as: Component,
  className = '',
  showArrow = true,
  children,
  ...props
}) => {
  return (
    <Component
      className={classNames(
        'relative inline-flex items-center justify-center text-sm font-medium py-2 transition-all bg-blue-600 text-white cursor-pointer rounded-full group overflow-hidden',
        showArrow ? 'pl-4 pr-9 hover:pl-9 hover:pr-4' : 'px-4',
        className
      )}
      {...props}
    >
      {showArrow && (
        <ArrowRightIcon className="w-4 h-4 absolute right-3 top-1/2 transition-all transform -translate-y-1/2 group-hover:-right-4 " />
      )}
      {children}
      {showArrow && (
        <ArrowRightIcon className="w-4 h-4 absolute -left-4 transition-all transform group-hover:left-3" />
      )}
    </Component>
  )
}

ButtonPrimary.defaultProps = {
  as: 'button',
}

export default ButtonPrimary
