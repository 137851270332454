// See .cache/page-templates after running dev/build
// to understand how this file ends up looking

import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../src/components/global/Layout'
import Seo from '../../src/components/global/Seo'

import BannerHomepage from '../../src/components/page/BannerHomepage'

const PageTemplate = pageProps => {
  let data, components

    data = pageProps.data.wpPage
    components = data['pageComponents']['pageComponents'] || []
  
  components = components.map(component => {
    return {
      name: component.__typename.split('_').pop(),
      data: component,
    }
  })
  const containsBannerHomepage = components.some(
    component => component.name === 'BannerHomepage'
  )
  return (
    <Layout showFooter={!containsBannerHomepage}>
      <Seo title={data.title} />
      {components.map((component, index) => {

      if (component.name === 'BannerHomepage') {
        return <BannerHomepage {...component.data} key={index} pageLocation={pageProps.location} />
      }
    
        return <div>Error: The component {component.name} was not found</div>
      })}
    </Layout>
  )
}

export default PageTemplate


    export const query = graphql`
      query PageQuery63($id: String!) {
        wpPage(id: {eq: $id}) {
          title
          
    pageComponents {
      pageComponents {
        __typename
         
 
      ... on WpPage_Pagecomponents_PageComponents_BannerHomepage {
        ...Page_BannerHomepage
      }
    
      }
    }
  
        }
      }
    `
  
