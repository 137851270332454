import React, { useRef, useState, useLayoutEffect } from 'react'
import {
  Canvas,
  useFrame,
  MeshProps,
  PerspectiveCameraProps,
} from '@react-three/fiber'
import * as THREE from 'three'
import {
  PerspectiveCamera,
  OrbitControls,
  OrbitControlsProps,
} from '@react-three/drei'
import CountryDotVertices from './points.json'
import { BufferGeometry } from 'three'
import BezierEasing from 'bezier-easing'
import ring from './ring.png'

const settings = {
  mapSize: {
    // Size of the map from the intial source image (on which the dots are positioned on)
    width: 2048 / 2,
    height: 1024 / 2,
  },
  globeRadius: 1, // Radius of the globe (used for many calculations)
  dotsAmount: 20, // Amount of dots to generate and animate randomly across the lines
  startingCountry: 'hongkong', // The key of the country to rotate the camera to during the,
}

const toSphericalCoords = (latitude: number, longitude: number) => {
  // Convert latitude and longitude on the 90/180 degree axis
  latitude =
    ((latitude - settings.mapSize.width) / settings.mapSize.width) * -180
  longitude =
    ((longitude - settings.mapSize.height) / settings.mapSize.height) * -90

  // Calculate the projected starting point
  const radius =
    Math.cos((longitude / 180) * Math.PI) * settings.globeRadius * 0.95
  const targetX = Math.cos((latitude / 180) * Math.PI) * radius
  const targetY =
    Math.sin((longitude / 180) * Math.PI) * settings.globeRadius * 0.95
  const targetZ = Math.sin((latitude / 180) * Math.PI) * radius
  return rotateY(
    {
      x: targetX,
      y: targetY,
      z: targetZ,
    },
    -0.8
  )
}

const rotateX = (vertex: any, angle: number) => {
  return {
    x: vertex.x,
    y: Math.cos(angle) * vertex.y - Math.sin(angle) * vertex.z,
    z: Math.sin(angle) * vertex.y + Math.cos(angle) * vertex.z,
  }
}

const rotateY = (vertex: any, angle: number) => {
  return {
    x: Math.cos(angle) * vertex.x + Math.sin(angle) * vertex.z,
    y: vertex.y,
    z: Math.cos(angle) * vertex.z - Math.sin(angle) * vertex.x,
  }
}

const rotateZ = (vertex: any, angle: number) => {
  return {
    x: Math.cos(angle) * vertex.x - Math.sin(angle) * vertex.y,
    y: Math.sin(angle) * vertex.x + Math.cos(angle) * vertex.y,
    z: vertex.z,
  }
}

const finalPoints = CountryDotVertices.points.map(p =>
  toSphericalCoords(p.x, p.y)
)

const GlobeWithAnimatedRing = () => {
  return (
    <>
      <img
        className="absolute inset-0 animate-[spin_10s_linear_infinite] opacity-50"
        src={ring}
        alt="Ring"
      />
      <Globe />
    </>
  )
}
export default GlobeWithAnimatedRing

const Globe = () => {
  return (
    <Canvas>
      <Camera />
      <Controls />
      <ambientLight />
      <CountryDots />
    </Canvas>
  )
}

const Camera = React.forwardRef((props: PerspectiveCameraProps, ref) => {
  return (
    <PerspectiveCamera
      fov={60}
      near={1}
      position={[0, 0, settings.globeRadius * 2.02]}
      makeDefault
      ref={ref}
      {...props}
    />
  )
})

const Controls = (props: OrbitControlsProps) => {
  return (
    // @ts-ignore
    <OrbitControls
      enablePan={false}
      enableZoom={false}
      enableDamping={true}
      dampingFactor={0.03}
      autoRotate={true}
      autoRotateSpeed={-0.8}
      minPolarAngle={Math.PI / 2 + 0.3}
      maxPolarAngle={Math.PI / 2 + 0.3}
      {...props}
    />
  )
}

const GlobeMesh = (props: MeshProps) => {
  const radius = settings.globeRadius * 0.98
  const widthSegments = 32
  const heightSegments = 32
  return (
    <mesh {...props}>
      <sphereGeometry args={[radius, widthSegments, heightSegments]} />
      <meshStandardMaterial color="#2563EB" transparent={true} opacity={0.03} />
    </mesh>
  )
}

const CountryDots = () => {
  const [current, setCurrent] = useState(0)
  const ref = useRef(null)
  const total = 50

  useLayoutEffect(() => {
    if (ref.current) {
      const geometry = ref.current as BufferGeometry
      geometry.setFromPoints(
        CountryDotVertices.points.map((vertex2d, i) => {
          let progress = easeInOutCubic(current / total)
          progress += progress * (i / CountryDotVertices.points.length)
          if (progress > 1) {
            progress = 1
          }
          const vertex3d = toSphericalCoords(vertex2d.x, vertex2d.y)
          return new THREE.Vector3(
            vertex3d.x * progress,
            vertex3d.y * progress,
            vertex3d.z * progress
          )
        })
      )
    }
  }, [current])
  useFrame(() => {
    if (current <= total) {
      setCurrent(current + 1)
    }
  })
  return (
    <points>
      <bufferGeometry ref={ref} />
      <pointsMaterial color="#2563EB" size={settings.globeRadius / 100} />
    </points>
  )
}

const easeInOutCubic = (t: number) => {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
}

const easeInCubic = BezierEasing(1, 0, 1, 0)
