import { ArrowNarrowRightIcon } from '@heroicons/react/outline'
import { motion, MotionConfig } from 'framer-motion'
import { graphql, Link, navigate, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import chunk from 'lodash.chunk'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { classNames, getImage, useMediaQuery } from '../../../utils/misc'
import ButtonPrimary from '../../global/ButtonPrimary'
import Tooltip from '../../global/Tooltip'
import Globe from './Globe'

const BannerHomepage = ({
  pageLocation,
  subtitle,
  title,
  description,
  buttonText,
  countrySnapshots,
}) => {
  const isDesktop = useMediaQuery('(min-width: 40rem)')
  console.log(`isDesktop ${isDesktop}`)
  const [state, setState] = useState(
    pageLocation.state?.skipToCountries ? 'second' : 'first'
  )

  useEffect(() => {
    if (pageLocation.state) {
      navigate(pageLocation.pathname, {
        state: {},
        replace: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const slider = {
    first: {
      left: 0,
    },
    second: {
      left: isDesktop ? '-50vw' : '-100vw',
    },
  }

  const restrictToDesktop = variants => {
    return isDesktop
      ? variants
      : {
          first: {},
          second: {},
        }
  }

  const staggerText = restrictToDesktop({
    first: {
      y: -100,
      opacity: 1,
      transition: {
        type: 'tween',
        ease: 'easeInOut',
      },
    },
    second: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 1,
        delay: 0.8,
        staggerChildren: 0.2,
        delayChildren: 1,
      },
    },
  })

  const text = restrictToDesktop({
    first: {
      y: -10,
      opacity: 0,
    },
    second: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 0.4,
      },
    },
  })

  const spaceBetween = 'sm:px-9'

  const data = useStaticQuery(graphql`
    query AllCountryQuery {
      allWpCountry {
        nodes {
          id
          title
          uri
          country {
            flagImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 50)
                }
              }
            }
            numberOfTwitterTakedowns
            stats {
              accountsInDataset
              total {
                tweets
              }
            }
            nestedCountry {
              __typename
              ... on WpCountry {
                id
              }
            }
          }
        }
      }
      wp {
        acfOptionsSiteConfiguration {
          siteConfiguration {
            tooltips {
              originatingCountry
            }
            privacyPolicyPage {
              __typename
              ... on WpPage {
                uri
              }
            }
            copyright
          }
        }
      }
    }
  `)

  const nestedCountries = new Set()
  data.allWpCountry.nodes.forEach(node => {
    if (node.country.nestedCountry) {
      nestedCountries.add(node.country.nestedCountry.id)
    }
  })
  const countryNodes = data.allWpCountry.nodes.filter(
    node => !nestedCountries.has(node.id)
  )
  const siteConfig = data.wp.acfOptionsSiteConfiguration.siteConfiguration
  const copyright = siteConfig.copyright.replace(
    '[current-year]',
    new Date().getFullYear()
  )

  const countryListRef = useRef()
  const [countryListHeight, setCountryListHeight] = useState()

  useLayoutEffect(() => {
    countryListRef?.current &&
      setCountryListHeight(countryListRef.current.clientHeight)
  }, [countryListRef])

  return (
    <MotionConfig transition={{ type: 'tween', duration: 1 }}>
      <section id="banner-homepage">
        <div className="relative h-[calc(100vh-64px)] sm:h-[calc(100vh-72px)] w-screen overflow-hidden">
          <motion.div
            animate={state}
            initial={false}
            layout
            variants={slider}
            className="absolute top-0 left-0 h-full flex"
          >
            <div className="w-screen h-full relative overflow-y-scroll overflow-x-hidden sm:overflow-hidden">
              <div className="absolute inset-0 flex flex-col-reverse sm:flex-row justify-end sm:justify-start px-4 sm:px-0">
                <div
                  className={classNames(
                    'sm:w-[50vw] flex items-center sm:py-5 justify-end',
                    spaceBetween
                  )}
                >
                  <motion.div
                    initial={false}
                    layout
                    className="w-full max-w-xl"
                  >
                    {subtitle && (
                      <p className="font-semibold uppercase tracking-wide mb-1 text-gray-500">
                        {subtitle}
                      </p>
                    )}
                    <div className="space-y-5">
                      <h1
                        className="text-5xl font-extrabold text-gray-900"
                        dangerouslySetInnerHTML={{ __html: title }}
                      ></h1>
                      <p className="mt-5 text-xl leading-7">{description}</p>
                      <ButtonPrimary
                        onClick={() => {
                          navigate(pageLocation.pathname, {
                            state: { skipToCountries: true },
                            replace: true,
                          })
                          setState('second')
                        }}
                      >
                        {buttonText}
                      </ButtonPrimary>
                      {!isDesktop && (
                        <div className="-mx-5 !mt-12 border-t border-gray-200">
                          <Copyright
                            copyright={copyright}
                            privacyPolicyUri={'/privacy-policy'}
                          />
                        </div>
                      )}
                    </div>
                  </motion.div>
                </div>
                <div
                  className={classNames(
                    'sm:w-[50vw] flex items-center pt-5 pb-2 sm:py-0 justify-center relative',
                    state === 'first' ? 'sm:justify-start' : 'sm:justify-end',
                    spaceBetween
                  )}
                >
                  <motion.div
                    initial={false}
                    layout
                    className="w-full max-w-[250px] sm:max-w-[min(576px,calc(100vh-150px))] h-full flex items-center relative"
                  >
                    <div className="w-full aspect-w-1 aspect-h-1">
                      <button onClick={() => setState('first')}>
                        <Globe />
                      </button>
                    </div>
                    {isDesktop && (
                      <div
                        className={classNames(
                          'absolute bottom-0 inset-x-0 max-w-xl py-5 flex',
                          state === 'first' ? 'justify-end' : 'justify-start'
                        )}
                      >
                        <motion.div initial={false} layout>
                          <Copyright
                            copyright={copyright}
                            privacyPolicyUri={siteConfig.privacyPolicyPage.uri}
                          />
                        </motion.div>
                      </div>
                    )}
                  </motion.div>
                </div>
              </div>
            </div>
            <div
              className={classNames(
                'w-screen sm:w-[50vw] h-full relative overflow-y-scroll overflow-x-hidden sm:overflow-hidden'
              )}
            >
              <div
                className={classNames(
                  'absolute inset-0 flex sm:items-center px-4 overflow-y-scroll',
                  spaceBetween
                )}
                style={{
                  paddingTop: isDesktop && countryListHeight ? 52 : 0,
                }}
              >
                <div className="w-full max-w-2xl py-6 max-h-full">
                  <motion.div
                    initial={false}
                    variants={staggerText}
                    ref={countryListRef}
                    style={{
                      height:
                        isDesktop && countryListHeight
                          ? countryListHeight + 52
                          : undefined,
                    }}
                  >
                    <motion.div
                      className="flex space-x-3"
                      initial={false}
                      variants={text}
                    >
                      <motion.h2
                        className="text-xl font-bold text-gray-700"
                        dangerouslySetInnerHTML={{
                          __html: countrySnapshots.title,
                        }}
                      />
                      <Tooltip
                        title="Originating Country"
                        description={siteConfig.tooltips.originatingCountry}
                      />
                    </motion.div>
                    <motion.p
                      className="mt-1.5 text-gray-600"
                      initial={false}
                      variants={text}
                    >
                      {countrySnapshots.description}
                    </motion.p>
                    {/* STUPID GATSBY SSR always renders this component with isDesktop === null if we don't do this check on typeof window */}
                    {typeof window === 'undefined' ? (
                      <></>
                    ) : isDesktop ? (
                      <div className="mt-6 grid grid-cols-2 gap-x-8">
                        {chunk(
                          countryNodes,
                          Math.ceil(countryNodes.length / 2)
                        ).map((chunky, i) => (
                          <div key={i}>
                            {chunky.map((node, i) => (
                              <motion.div
                                key={i}
                                initial={false}
                                variants={{
                                  first: {
                                    y: -10,
                                    opacity: 0,
                                  },
                                  second: {
                                    y: 0,
                                    opacity: 1,
                                    transition: {
                                      delay: 1.4 + i * 0.2,
                                      type: 'tween',
                                      ease: 'easeInOut',
                                    },
                                  },
                                }}
                              >
                                <Country key={i} node={node} />
                              </motion.div>
                            ))}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="mt-6">
                        {countryNodes.map((node, i) => (
                          <motion.div
                            key={i}
                            variants={{
                              first: {
                                y: -10,
                                opacity: 0,
                              },
                              second: {
                                y: 0,
                                opacity: 1,
                                transition: {
                                  delay: 0.8 + i * 0.2,
                                  type: 'tween',
                                  ease: 'easeInOut',
                                },
                              },
                            }}
                          >
                            <Country node={node} />
                          </motion.div>
                        ))}
                      </div>
                    )}
                  </motion.div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
    </MotionConfig>
  )
}

export default BannerHomepage

const Copyright = ({ copyright, privacyPolicyUri }) => {
  return (
    <div className="p-4">
      <p className="text-gray-400">{copyright}</p>
    </div>
  )
}

const Country = ({ node }) => {
  return (
    <Link
      className="block border-t px-1.5 py-3 border-gray-100 group"
      to={node.uri}
    >
      <div className="flex items-center">
        <div className="rounded-sm p-0.5 shadow-md">
          <GatsbyImage
            image={getImage(node.country.flagImage)}
            alt={`${node.title} flag`}
            className="rounded-sm"
          />
        </div>
        <h3 className="ml-4 font-semibold">{node.title}</h3>
        <ArrowNarrowRightIcon className="ml-auto box-content w-6 h-6 text-blue-600 pr-3 group-hover:pr-0 transition-all" />
      </div>

      <div className="overflow-hidden max-h-0 transition-all duration-300 group-hover:max-h-14 text-sm space-y-1">
        <div className="pt-2 flex space-x-6">
          <div className="flex space-x-3">
            <p className="text-gray-400 font-medium">Accounts</p>
            <p className="text-blue-600 font-semibold">
              {node.country.stats.accountsInDataset.toLocaleString()}
            </p>
          </div>
          <div className="flex space-x-3">
            <p className="text-gray-400 font-medium">Takedowns</p>
            <p className="text-blue-600 font-semibold">
              {node.country.numberOfTwitterTakedowns.toLocaleString()}
            </p>
          </div>
        </div>
        <div className="flex space-x-3">
          <p className="text-gray-400 font-medium">Total Tweets</p>
          <p className="text-blue-600 font-semibold">
            {node.country.stats.total.tweets.toLocaleString()}
          </p>
        </div>
      </div>
    </Link>
  )
}
